// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$skillo-primary: mat-palette($mat-indigo);
$skillo-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$skillo-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$skillo-theme: mat-light-theme(
  (
    color: (
      primary: $skillo-primary,
      accent: $skillo-accent,
      warn: $skillo-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($skillo-theme);

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* You can add global styles to this file, and also import other style files */
$md-blue: (
  50: #e6f3f8,
  100: #c2e2ee,
  200: #99cfe3,
  300: #70bbd7,
  400: #51adcf,
  500: #329ec6,
  600: #2d96c0,
  700: #268cb9,
  800: #1f82b1,
  900: #1370a4,
  A100: #d6efff,
  A200: #a3dbff,
  A400: #70c7ff,
  A700: #57beff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$custom-blue: (
  50: #eae9fd,
  100: #cbc8fb,
  200: #a8a3f9,
  300: #857ef6,
  400: #6b63f4,
  500: #5147f2,
  600: #4a40f0,
  700: #4037ee,
  800: #372fec,
  900: #2720e8,
  A100: #ffffff,
  A200: #edecff,
  A400: #bbb9ff,
  A700: #a3a0ff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$skillo-primary: mat-palette($custom-blue);
$skillo-accent: mat-palette($md-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$skillo-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$skillo-theme: mat-light-theme(
  (
    color: (
      primary: $skillo-primary,
      accent: $skillo-accent,
      warn: $skillo-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($skillo-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
