body {
  min-width: 290px;
}
a {
  text-decoration: none;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.minheight {
  min-height: 100vh !important;
}
